const pageController = {
	init : () => {
		Q('#start')[ 0 ].addEventListener('click', () => {
			pageController.start();
			pageController.videoNext();
		});
		Q('#video-next')[ 0 ].addEventListener('click', () => {
			pageController.videoNext();
		});
		
		let internalTimeout = null;
		let internalCounter = 0;
		const escape = () => {
			internalCounter++;
			if(internalCounter > 5){
				location.reload();
			}
			clearTimeout(internalTimeout);
			internalTimeout = setTimeout(()=>{
				internalCounter = 0;
			},5000);
		};
		Q('#escape')[ 0 ].addEventListener('mouseup', escape);
		Q('#escape')[ 0 ].addEventListener('touchend', escape);
		Q('#open-game-rule')[ 0 ].addEventListener('click', () => {
			Q('#container-game-rule')[0].classList.remove('hidden');
		});
		Q('#close-game-rule')[ 0 ].addEventListener('click', () => {
			Q('#container-game-rule')[0].scrollTo(0,0);
			Q('#container-game-rule')[0].classList.add('hidden');
		});
	},
	reset : () => {
		// Q('video')[ 0 ].currentTime = 0;
	},
	start : () => {
		pageController.reset();
		formController.reset();
		Q('.page[data-page="start"]')[ 0 ].classList.add('out');
		Q('.page[data-page="video"]')[ 0 ].classList.remove('out');
		// Q('video')[ 0 ].play();
	},
	videoNext : () => {
		// Q('video')[ 0 ].pause();
		Q('.page[data-page="video"]')[ 0 ].classList.add('out');
		Q('.page[data-page="questions"]')[ 0 ].classList.remove('out');
		questionsController.start();
	},
	endForm : () => {
		Q('.page[data-page="form"]')[ 0 ].classList.add('out');
		Q('.page[data-page="end"]')[ 0 ].classList.remove('out');
		// setTimeout(() => {
		// 	Q('.page[data-page="end"]')[ 0 ].classList.add('out');
		// 	Q('.page[data-page="start"]')[ 0 ].classList.remove('out');
		// }, 5000);
	},
	endNext : ()=>{
		Q('.page[data-page="end"]')[ 0 ].classList.add('out');
		Q('.page[data-page="start"]')[ 0 ].classList.remove('out');
	},
};

const questionsController = {
	init : () => {
		const container = Q('#container-questions')[ 0 ];
		questions.forEach(( item, index ) => {
			const questionDom = H('#template-question', {
				text : item.question,
				index : index,
			}, container, false);
			item.dom = questionDom[ 0 ];
			const inputContainer = Q('.input-container', questionDom)[ 0 ];
			/**
			 * @type {null|Input}
			 */
			let input = null;
			let options = {};
			// if ( index === questions.length-1 ) {
			// 	options = {
			// 		type : 'number',
			// 		events : {
			// 			keyup : () => {
			// 				input.value = input.value.replace(/\D/g, '');
			// 			}
			// 		}
			// 	};
			// }
			if ( item.type === 'radio' ) {
				input = new InputRadio(
					inputContainer,
					item.options.map(( str ) => {
						return {
							text : str,
						}
					}),
					options
				);
			}else if(item.type === 'checkbox'){
				input = new InputCheckbox(
					inputContainer,
					item.options.map(( str ) => {
						return {
							text : str,
						}
					}),
					options
				);
			} else {
				input = new InputText(
					inputContainer,
					{
						...options,
						placeholder : item.placeholder,
					}
				);
			}
			item.input = input;
			const next = Q('.question-next', questionDom)[ 0 ];
			next.addEventListener('click', () => {
				if ( input.validate() ) {
					questionDom[ 0 ].classList.add('out');
					const nextQuestion = index + 1;
					if ( nextQuestion < questions.length ) {
						questions[ nextQuestion ].dom.classList.remove('out');
						const image = Q('.question-image:not(.hidden)')[0];
						image.classList.add('hidden');
						if(image.nextElementSibling){
							image.nextElementSibling.classList.remove('hidden');
						}else{
							Q('.question-image')[0].classList.remove('hidden');
						}
					} else {
						questionsController.finish();
					}
				}
			});
		});
		questionsController.start();
	},
	reset : () => {
		questions.forEach(( item ) => {
			item.dom.classList.add('out');
			item.input.value = null;
		});
	},
	start : () => {
		questionsController.reset();
		questions[ 0 ].dom.classList.remove('out');
	},
	get : () => {
		/**
		 * @type {[string]}
		 */
		const res = [];
		questions.forEach(( item ) => {
			res.push(item.input.value);
		});
		return res;
	},
	finish : () => {
		Q('.page[data-page="questions"]')[ 0 ].classList.add('out');
		formController.start();
	}
};

const formController = {
	init : () => {
		const container = Q('#container-form')[ 0 ];
		const showFormBit = ( key, state ) => {
			const element = form[ key ].dom;
			if ( state ) {
				element.classList.remove('hidden');
			} else {
				element.classList.add('hidden');
				form[ key ].input.value = null;
			}
		};
		Object.entries(form).forEach(
			/**
			 *
			 * @param {[string,FormBit]} item
			 */
			( [ key, formBit ] ) => {
				const formBitDom = H('#template-form-bit', {
					text : formBit.text,
					key : key,
				}, container, false);
				formBit.dom = formBitDom[ 0 ];

				const inputContainer = Q('.input-container', formBitDom)[ 0 ];
				/**
				 * @type {null|Input}
				 */
				let input = null;
				let options = {};
				if ( key === 'buying_used_car' ) {
					options = {
						// events : {
						// 	change : ( a ) => {
						// 		showFormBit(
						// 			'used_car_planned_cost',
						// 			input.value.text === 'Igen'
						// 		);
						// 	}
						// },
					};
				} else if ( key === 'phone' ) {
					options = {
						type : 'number',
						events : {
							keyup : ( a ) => {
								input.value = input.value.replace(/[^\d\-\+ ]/g, '');
							}
						},
					};
				} else if ( key === 'email' ) {
					options = {
						type : 'email',
						validateOverride : ( e ) => {
							return Validators.validateEmail(input.value);
						}
					};
				}
				if ( formBit.type === 'radio' ) {
					input = new InputRadio(
						inputContainer,
						formBit.options.map(( str ) => {
							return {
								text : str,
							}
						}),
						options
					);
				} else {
					input = new InputText(
						inputContainer,
						{
							...options,
							placeholder : formBit.placeholder,
						}
					);
				}
				formBit.input = input;
			}
		);
		// showFormBit('used_car_planned_cost', false);
		Q('#form-next')[ 0 ].addEventListener('click', () => {
			let isValid = true;
			const formData = {};
			Object.entries(form).filter(( [ key, formBit ] ) => {
				return key !== 'used_car_planned_cost';
			}).forEach(
				/**
				 *
				 * @param {[string,FormBit]} item
				 */
				( [ key, formBit ] ) => {
					if ( formBit.type === 'free' ) {
						formBit.input.value = formBit.input.value.trim();
					}
					const formBitValid = formBit.input.validate();
					isValid = isValid && formBitValid;
					if ( formBitValid ) {
						formBit.dom.classList.remove('error');
						if ( formBit.type === 'radio' ) {
							formData[ key ] = formBit.input.value.text;
						} else {
							formData[ key ] = formBit.input.value;
						}
					} else {
						formBit.dom.classList.add('error');
					}
				}
			);

			/*
			if (
				form[ 'buying_used_car' ].input.validate()
				&&
				form[ 'buying_used_car' ].input.value.text === 'Igen'
			) {
				const ucpcInput = form[ 'used_car_planned_cost' ].input;
				const ucpcValid = ucpcInput.validate();
				isValid = isValid && ucpcValid;
				if ( ucpcValid ) {
					formData[ 'used_car_planned_cost' ] = ucpcInput.value.text;
				}
			} else {
				formData[ 'used_car_planned_cost' ] = '-';
			}
			 */
			formData[ 'used_car_planned_cost' ] = '-';

			if ( isValid ) {
				const questionsData = [];

				questions.forEach(( item ) => {
					if ( item.type === 'radio' ) {
						questionsData.push(item.input.value.text);
					}else if ( item.type === 'checkbox' ) {
						questionsData.push(item.input.value.map((item)=>{
							return item.text;
						}));
					} else {
						questionsData.push(item.input.value);
					}
				});

				let correctAnswerCount = formController.checkCorrectAnswers(questionsData);
				questionsData.push(correctAnswerCount);
				Q('#correct-answer-count')[0].innerHTML = correctAnswerCount;
				formController.send({
					form : {
						...formData
					},
					questions : questionsData,
				});
			}
		});
		Q('#end-next')[0].addEventListener('click',()=>{
		    pageController.endNext();
		});
	},
	checkCorrectAnswers : (questionsData)=>{
		let correctAnswerCount = 0;
		questionsData.forEach((item, index)=>{
			const correctAnswers = [];
			questionsCorrect[index].forEach((item)=>{
				correctAnswers.push(questions[index].options[item]);
			});
			const checkCorrect = (answer)=>{
				if ( correctAnswers.includes(answer) ) {
					correctAnswerCount++;
				}
			};
			if(Array.isArray(item)){
				item.forEach((answer)=>{
					checkCorrect(answer);
				});
			}else{
				checkCorrect(item);
			}
		});
		return correctAnswerCount;
	},
	reset : () => {
		Object.entries(form).forEach(
			/**
			 *
			 * @param {[string,FormBit]} item
			 */
			( [ key, formBit ] ) => {
				formBit.input.value = null;
			}
		);
	},
	start : () => {
		formController.reset();
		Q('.page[data-page="form"]')[ 0 ].classList.remove('out');
	},
	send : ( data ) => {
		console.log(data);

		const url = `${ baseUrl }/register`;
		window.sendBlock = true;
		fetch(url, {
			method : 'POST',
			headers : {
				'Content-Type' : 'application/json',
			},
			body : JSON.stringify(data),
			credentials : 'same-origin'
		}).then(( raw ) => {
			return raw.json();
		}).then(( res ) => {
			console.log(res);
			if ( !res.error ) {
				pageController.endForm();
			}
		}).catch(( error ) => {
			console.error(error);
		}).finally(() => {
			window.sendBlock = false;
		});
	},
};

( () => {
	console.log('main');
	pageController.init();
	questionsController.init();
	formController.init();
} )();


function initForm() {
	new InputText(Q('#input-container-mki')[ 0 ], {
		autocomplete : ac1,
	});
}